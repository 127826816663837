

body {
  font: 14px 'Roboto', sans-serif;
  background-color: lightblue;
}

.board-row:after {
  clear: both;
  content: "";
  display: table;
}

.game-status {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 1.5em;
  padding-top: 20px;
  padding-bottom: 10px;
}

.status {
  margin-bottom: 10px;
}

.gamestartbtn {
  color: white;
  border-radius: 10px;
  border: 0;
  padding: 0.5em;
  position: relative;
  top: 10px;
  outline:none;

}


.square {
  background: #fff;
  border: 1px solid #999;
  float: left;
  font-size: 50px;
  font-weight: bold;
  line-height: 1.5em;
  height: 1.5em;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 1.5em;
}

.square:focus {
  outline: none;
}

.kbd-navigation .square:focus {
  background: #ddd;
}


/* .game-info {
  margin-left: 1rem;
  margin-right: 0.5rem;
  padding-top: 4.9rem;
}
 */

 .gamebody {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 8rem;
}
.ExtContainer {

  text-align: center;
  background: lightblue;
  width: 17.2em;
  height: 17.2em;
  /* Center vertically and horizontally */
}




/* .googleauthbutton {
  padding-left: 70%;
} */






